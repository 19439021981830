
export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "",

  author: "Enis Bilgin",
  description: "Personal blog to explain things to myself.",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
