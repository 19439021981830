import React from 'react'
import styles from './Bio.module.css'

function Bio(props) {
  let photoURL = "https://github.com/enis-bilgin.png"

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={photoURL} alt="Me" />
      <p>
        Overloaded, Personal & Technical Blog <br/>
          {'  '}
        <a href="https://www.linkedin.com/in/enisbilgin/"> e.bilgin </a>
        <br />
      </p>
    </div>
  )
}

export default Bio
